(function($, Drupal) {
  Drupal.behaviors.ada = {
    attach: function() {
      var $tabbableElements = $();
      var timer;
      var $html = $('html');

      $(document).off('keydown').on('keydown', function(e) {
        var key = e.key;
        if (['Tab', 'ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape', ' '].indexOf(key) > -1) {
          $tabbableElements = $(':tabbable');
          var $currentElementFocused = $(':focus');
          var index = $tabbableElements.index($currentElementFocused);
          if (!$html.hasClass('ada-enabled')) {
            $html.addClass('ada-enabled');
          }

          switch (key) {
            case 'ArrowLeft':
            case 'ArrowUp':
              if (index === 0) {
                // If the element is the first then focus the last element.
                focusElement(-1);
              } else {
                focusElement(index - 1);
              }
              break;
            case 'ArrowRight':
            case 'ArrowDown':
              if (index === $tabbableElements.length - 1) {
                // If the element is the last then focus the first element.
                focusElement(0);
              } else {
                focusElement(index + 1);
              }
              break;
            case 'Enter':
            case ' ':
              if (timer) {
                clearTimeout(timer);
              }
              // Click will be fired if the element is diffent than a and button html tag.
              if (!$currentElementFocused.is('a') && !$currentElementFocused.is('button') && !$currentElementFocused.is(':checkbox')) {
                $currentElementFocused.click();
              }
              if ($currentElementFocused.hasClass('close-popup')) {
                // Focus previous element after a time in case it takes time to load
                focusTimer(index - 1);
              }
              // If the link/label has pop up then click to open
              if ($currentElementFocused[0] !== undefined && $currentElementFocused[0].hasAttribute('aria-haspopup')) {
                // Focus next element after a time in case it takes time to load
                focusTimer(index + 1);
              }
              break;

            case 'Escape':
              focusElement(index - 1);
              break;
          }

          $(document).off('mousemove').one('mousemove', function() {
            if ($html.hasClass('ada-enabled')) {
              $html.removeClass('ada-enabled');
            }
          });
        }
      });

      function focusElement(index) {
        $tabbableElements.eq(index).focus();
      }

      function focusTimer(index) {
        timer = setTimeout(function() {
          // Update the var to get all the new tabbable elements.
          $tabbableElements = $(':tabbable');
          focusElement(index);
        }, 350);
      }

      /**
       * :focusable and :tabbable, both taken from jQuery UI Core
       * @TODO: If jQuery UI(jquery.ui.core) is updated to v1.10.2 the following extend must be removed.
       */
      $.extend($.expr[ ':' ], {
        data: $.expr.createPseudo ?
          $.expr.createPseudo(function(dataName) {
            return function(elem) {
              return !!$.data(elem, dataName);
            };
          }) :
          // support: jQuery <1.8
          function(elem, i, match) {
            return !!$.data(elem, match[ 3 ]);
          },

        focusable: function(element) {
          return focusable(element, !isNaN($.attr(element, 'tabindex')));
        },

        tabbable: function(element) {
          var tabIndex = $.attr(element, 'tabindex');
          var isTabIndexNaN = isNaN(tabIndex);
          return (isTabIndexNaN || tabIndex >= 0) && focusable(element, !isTabIndexNaN);
        }
      });

      /**
       * focussable function, taken from jQuery UI Core.
       * @param element
       * @returns {*}
       * @TODO: If jQuery UI(jquery.ui.core) is updated to v1.10.2 the following focusable function must be removed.
       */
      function focusable(element) {
        var map;
        var mapName;
        var img;
        var nodeName = element.nodeName.toLowerCase();
        var isTabIndexNotNaN = !isNaN($.attr(element, 'tabindex'));

        if (nodeName === 'area') {
          map = element.parentNode;
          mapName = map.name;
          if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
            return false;
          }
          img = $('img[usemap=#' + mapName + ']')[0];
          return !!img && visible(img);
        }
        return (/^(input|select|textarea|button|object)$/.test(nodeName) ?
          !element.disabled :
          nodeName === 'a' ?
            element.href || isTabIndexNotNaN :
            isTabIndexNotNaN) &&
          // The element and all of its ancestors must be visible.
          visible(element);

        function visible(element) {
          return $.expr.filters.visible(element) && !$(element).parents().addBack().filter(function() {
            return $.css(this, 'visibility') === 'hidden';
          }).length;
        }
      }
    }
  };
})(jQuery, Drupal);
